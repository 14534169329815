import Script from 'next/script';
import {useSelector} from 'react-redux';

import type {AppState} from '@jobseeker/common-core/lib/types';
import {DOMAIN} from '@jobseeker/common-core/lib/constants';
import {Box} from '@mui/material';

export const StoryInjection = () => {
  const {
    userId,
    authInfo: {token},
  } = useSelector((state: AppState) => state.auth);

  return (
    <>
      <Box
        id="company-story-feed"
        display={{xxs: 'none', md: token ? 'block' : 'none'}}
        borderRadius={'16px'}
        boxShadow={'0px 6.48px 6px 2px rgba(41, 41, 41, 0.02)'}
        padding={'4px 16px 16px 16px'}
        mb={2}
        sx={{
          background: 'white',
          cursor: 'pointer',
          minHeight: '136px',
        }}
      />
      {token && (
        <Script
          id={`${process.env.NEXT_PUBLIC_APP_NAME}-story-feed`}
          dangerouslySetInnerHTML={{
            __html: `
              const gaLabel = 'Origin=jobfeed, UserId=${userId}, Device=Web';

              // TEMPORARY EVENT TO HANDLE GAQ UNDEFINED ERROR 
              // TODO: Remove after implementing Google Analytics

              const _gaq = { push: ([...args]) => console.log('GAQ', args) };
              const config = {
                domain: '${DOMAIN}',
                authToken: '${token}',
                gaEventCbs: {
                  view: (storyData) => {
                    _gaq.push([
                      '_trackEvent',
                      'WebStoriesIimjobs',
                      'jsViewsWebStories',
                      gaLabel,
                    ]);
                    window?.tracking?.doTracking({
                      uba: { 
                        eventName: 'storiesView', 
                        keyNames: {
                          actionSrc: 'normal',
                          actionType: 'view',
                          sessionUsername: sessionStorage.getItem('userId') || '',
                          ref: new URL(window.location.href).searchParams.get('ref') || '',
                          storyId: storyData?.storyId,
                          companyID: storyData.header?.companyId || "",
                          companyName: storyData.header?.heading || "",
                        }
                      },
                    });
                  },
                  swipeup: (storyData) => {
                    _gaq.push([
                      '_trackEvent',
                      'WebStoriesIimjobs',
                      'jsClickCtaWebStories',
                      gaLabel,
                    ]);
                    window?.tracking?.doTracking({
                      uba: { 
                        eventName: 'storiesClick', 
                        keyNames: {
                          actionSrc: 'normal',
                          actionType: 'click',
                          sessionUsername: sessionStorage.getItem('userId') || '',
                          ref: new URL(window.location.href).searchParams.get('ref') || '',
                          companyID: storyData?.companyId || "",
                          companyName: storyData?.companyName || "",
                          storyID: storyData?.storyId || "",
                          clickType: "SwipUp",
                        }
                      },
                    });
                  },
                  title: () => {
                    _gaq.push([
                      '_trackEvent',
                      'WebStoriesIimjobs',
                      'jsClickCompanyNameWebStories',
                      gaLabel,
                    ]);
                  },
                  clap: (storyData) => {
                    _gaq.push([
                      '_trackEvent',
                      'WebStoriesIimjobs',
                      'jsClickClapWebStories',
                      gaLabel,
                    ]);
                    window?.tracking?.doTracking({
                      uba: { 
                        eventName: 'storiesClick', 
                        keyNames: {
                          actionSrc: 'normal',
                          actionType: 'click',
                          sessionUsername: sessionStorage.getItem('userId') || '',
                          ref: new URL(window.location.href).searchParams.get('ref') || '',
                          clickType: "Clap",
                          companyID: storyData?.companyId || "",
                          companyName: storyData?.companyName || "",
                          storyID: storyData?.storyId || "",
                        }
                      },
                    });
                  }
                }
              };
              console.log('Loaded the story feed');
              if (typeof window !== 'undefined') {
                window.storyFeedPlugin?.storyFeedPluginInit(config);
              }
              `,
          }}
        />
      )}
    </>
  );
};

export default StoryInjection;
