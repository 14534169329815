import {ErrorBoundary as ReactErrorBoundary} from 'react-error-boundary';

type ErrorBoundaryProps = {children: React.ReactNode};

const ErrorFallBak = () => <>Oops! something went wrong, please try again</>;

const ErrorBoundary = ({children}: ErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallBak}
      onError={(error: Error, info: {componentStack: string}) => {
        console.log('🚀 ~ file: index.tsx:49 ~ JobFeedPage ~ info:', info);
        console.log(
          '🚀 ~ file: index.tsx:49 ~ JobFeedPage ~ componentStack:',
          error.message
        );
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
