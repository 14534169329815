import {ReactNode} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import {Box} from '@jobseeker/lotus/lib/foundations';
import {useIsLargeDevice} from '@jobseeker/lotus/lib/hooks';
import {VersionVariants} from '@jobseeker/lotus/lib/enums';
import Breadcrumbs from '@jobseeker/lotus/lib/components/Breadcrumbs';
import {BreadcrumbJsonLd} from 'next-seo';
import ErrorBoundary from '@components/ErrorBoundary';
import {truncateString} from '@utils';

import type {AppState} from '@jobseeker/common-core/lib/types';
import {EPOCH, PERSONALIZE_JOBFEED} from '@constants';

interface LayoutProps {
  children: ReactNode;
  breadcrumbElements: {name: string; item: string}[];
  variant?: string;
}

const getBreadcrumbsElements = (
  breadcrumbElements: Array<{
    name: string;
    item: string;
  }>,
  device = 'desktop'
) => {
  return breadcrumbElements?.map((element, index) => {
    if (element?.item && index < element.item.length) {
      return (
        <Typography
          key={`breadcrumb-layout-${index}`}
          fontSize={10}
          color={'secondary.main'}
        >
          <a
            href={element?.item}
            style={{
              textDecoration: 'none',
              color: '#333333',
            }}
          >
            {element?.name}
          </a>
        </Typography>
      );
    }
    return (
      <Typography
        key={`breadcrumb-title-${index}`}
        fontSize={10}
        color={'secondary.main'}
        sx={{
          overflowX: 'hidden',
          overflowY: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {device === 'mobile'
          ? truncateString(element?.name, 24)
          : element?.name}
      </Typography>
    );
  });
};

const getAppendedLinkForBreadcrumb = (
  breadcrumbElements: Array<{
    name: string;
    item: string;
  }>
) => {
  return breadcrumbElements?.map((breadCrumbInfo, index) => {
    if (breadCrumbInfo?.name) {
      const breadElement: {position: number; name: string; item?: string} = {
        position: index + 1,
        name: breadCrumbInfo?.name,
      };
      if (breadcrumbElements?.length - 1 !== index) {
        breadElement['item'] = `${
          process.env.NEXT_PUBLIC_LOAD_CONFIG
        }${breadCrumbInfo?.item?.split('?')[0]}`;
      }
      return breadElement;
    }
    return breadCrumbInfo;
  });
};

const ResponsiveLayout = (props: LayoutProps) => {
  const {children, breadcrumbElements, variant} = props || {};

  const isAboveXl = useIsLargeDevice(2560);
  const page = useSelector((state: AppState) => state.app.page, shallowEqual);

  const {device} = useSelector(
    (state: AppState) => state.app.layout,
    shallowEqual
  );

  const breadCrumbs = getBreadcrumbsElements(breadcrumbElements, device);

  const boxSx = {
    background: variant === VersionVariants.VERSION_TWO ? '#F3F7FA' : '#fff',
    py: {
      xxs: breadcrumbElements.length ? 10 : page?.indexOf(EPOCH) > -1 ? 6 : 8,
      md: page == 'jobfeed' ? 10 : page?.indexOf(EPOCH) > -1 ? 8 : 10,
    },
    minHeight:
      page?.indexOf(PERSONALIZE_JOBFEED) > -1 || page?.includes('registration-')
        ? '100vh'
        : '58vh',
    px:
      variant === VersionVariants.VERSION_TWO
        ? 2
        : !isAboveXl
        ? {xxs: 2, md: 4, xl: 15}
        : 66,
  };

  return (
    <ErrorBoundary>
      <Box
        display={'flex'}
        justifyContent={'center'}
        sx={{
          ...boxSx,
          ...(page?.indexOf(PERSONALIZE_JOBFEED) > -1
            ? {paddingBottom: '0px !important'}
            : {}),
        }}
      >
        <Box maxWidth={1440} width={'100%'}>
          <BreadcrumbJsonLd
            itemListElements={getAppendedLinkForBreadcrumb(breadcrumbElements)}
          />
          {breadcrumbElements?.length > 0 && (
            <Box pb={2}>
              <Breadcrumbs
                variant={VersionVariants.VERSION_TWO}
                separator={
                  <Typography fontSize={10} color={'secondary.main'}>
                    {'>'}
                  </Typography>
                }
                breadcrumbs={breadCrumbs}
              />
            </Box>
          )}
          {children}
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default ResponsiveLayout;
