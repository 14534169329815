/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Dispatch, useCallback, useEffect, useState, memo, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import debounce from 'lodash/throttle';
import kebabCase from 'lodash/kebabCase';
import {getCookie, deleteCookie} from 'cookies-next';
import Dialog from '@jobseeker/lotus/lib/components/Dialog';

import Grid from '@jobseeker/lotus/lib/foundations/Layouts/Grid';
import {VersionVariants} from '@jobseeker/lotus/lib/enums/variants';
import Loader from '@jobseeker/lotus/lib/components/Loader';
import useInterceptor from '@jobseeker/common-core/lib/hooks/useInterceptor';
import {useIsLargeDevice} from '@jobseeker/lotus/lib/hooks';
import {calendarService} from '@jobseeker/common-core/lib/services';
import {handleTracking, TrackEventUBA} from '@jobseeker/common-core/lib/utils';
import {getRefEmbedded} from '@jobseeker/common-core/lib/utils/refTrack';
import {CautionIcon, ChevronRight} from '@jobseeker/lotus/lib/assets/icons';
import Image from 'next/image';

import {
  fetchCategoryJobFeed,
  fetchRecuiterJobFeed,
  fetchTagJobFeed,
  fetchUserJobFeed,
  searchJobsbyQuery,
  fetchLocationBasedJobFeed,
  fetchIndustryData,
} from '@jobseeker/common-core/lib/reducers/job/thunkServices/jobfeed';

import {openUrl} from '@jobseeker/common-core/lib/utils/common';

import InjectSearchOverlay from '@components/SearchInjection';
import {
  CATEGORY,
  EXPERIENCE_MAPPING,
  JOBFEED,
  LOCATION_BASED,
  MYJOBFEED,
  RECRUITER_JOBFEED,
  ROUTE_JOB_DETAIL,
  ROUTE_SEARCH,
  SEARCH,
  TAG,
} from '@constants';
import moment from 'moment';
import {getOldLink, getProLink, isCSR} from '@jobseeker/lotus/lib/utils';
import StoryInjection from '@components/StoryInjection';

import {getLocationIds, setQueryParams} from '@utils';
import {isEqual} from 'lodash';
import {Typography, Link} from '@mui/material';
import Button from '@jobseeker/lotus/lib/elements/Button';
import {Box} from '@jobseeker/lotus/lib/foundations/Layouts/Box';
import {ParsedUrlQuery} from 'querystring';
import {SeoIntrup} from '../../SeoLayout';
import {getListSEOContent} from '@utils/seo';

import type {AppState} from '@jobseeker/common-core/lib/types';

type JobFeedLayoutProps = {
  jobFeedType: string;
  catOrTagId: string;
  catOrTagTitle: string;
  showProfileCard: boolean;
  showFeatured?: boolean;
  showLearning?: boolean;
  showLearningCenterCTA?: boolean;
  variant?: VersionVariants;
  feedOnly?: boolean;
  calledType?: string;
  preFilters?:
    | {
        page: number;
      }
    | any;
};

type CallParamType = {
  userId: string;
  catOrTagId: string;
  slugId?: number;
  type?: string;
  page?: number;
  minexp?: number;
  maxexp?: number;
  param?: any;
};

const getJobFeedCallByType = (
  type: string,
  callParam: CallParamType,
  dispatch: Dispatch</* eslint-disable @typescript-eslint/no-explicit-any */ any>
) => {
  const feedCall: {
    [key: string]: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
  } = {
    [CATEGORY]: () => {
      return dispatch(
        fetchCategoryJobFeed({
          userId: callParam?.userId,
          pageNo: callParam?.page,
          minexp: callParam?.minexp,
          maxexp: callParam?.maxexp,
          catId: callParam?.catOrTagId,
          ...callParam.param,
          loc: callParam?.param?.loc ? callParam?.param?.loc.join(',') : null,
        })
      );
    },
    [TAG]: () => {
      return dispatch(
        fetchTagJobFeed({
          userId: callParam?.userId,
          pageNo: callParam?.page,
          minexp: callParam?.minexp,
          maxexp: callParam?.maxexp,
          query: callParam?.catOrTagId,
          ...callParam.param,
          loc: callParam?.param?.loc ? callParam?.param?.loc.join(',') : null,
        })
      );
    },
    [SEARCH]: () => {
      return dispatch(
        searchJobsbyQuery({
          userId: callParam?.userId,
          pageNo: callParam?.page,
          minexp: callParam?.minexp,
          maxexp: callParam?.maxexp,
          query: callParam?.catOrTagId,
          ...callParam.param,
          loc: callParam?.param?.loc ? callParam?.param?.loc.join(',') : null,
        })
      );
    },
    [JOBFEED]: () => {
      return dispatch(
        fetchUserJobFeed({
          userId: callParam?.userId,
          pageNo: callParam?.page,
          minexp: callParam?.minexp,
          maxexp: callParam?.maxexp,
          ...callParam.param,
          loc: callParam?.param?.loc ? callParam?.param?.loc.join(',') : null,
        })
      );
    },
    [LOCATION_BASED]: async () => {
      return dispatch(
        fetchLocationBasedJobFeed({
          ...callParam.param,
          userId: callParam?.userId,
          id: callParam?.catOrTagId,
          type: callParam?.type,
        })
      );
    },
    [RECRUITER_JOBFEED]: () => {
      return dispatch(
        fetchRecuiterJobFeed({
          userId: callParam?.userId,
          pageNo: callParam?.page,
          minexp: callParam?.minexp,
          maxexp: callParam?.maxexp,
          recruiterId: callParam?.catOrTagId,
          ...callParam.param,
          loc: callParam?.param?.loc ? callParam?.param?.loc.join(',') : null,
        })
      );
    },
  };

  return feedCall[type]();
};

const ProfileCardHarness = dynamic(
  () => import('@jobseeker/client-harness/lib/components/ProfileHarness'),
  {
    ssr: false,
  }
);

const JobFeedEmpty = dynamic(() => import('@components/Empty/Jobfeed'), {
  loading: () => <Loader />,
});

const LoginForm = dynamic(
  () =>
    import(
      '@jobseeker/client-harness/lib/components/AuthenticationHarness/AuthForm'
    ),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const AdsPanelHarness = memo(
  dynamic(
    () =>
      import('@jobseeker/client-harness/lib/components/AdsHarness/AdsPanelV2'),
    {
      loading: () => <Loader />,
    }
  )
);

const JobfeedHarness = memo(
  dynamic(
    () =>
      import('@jobseeker/client-harness/lib/components/JobHarness/JobfeedV2'),
    {
      loading: () => <Loader />,
    }
  )
);
const CTA = dynamic(() => import('@jobseeker/lotus/lib/components/CTA'), {
  loading: () => <Loader />,
  ssr: false,
});

const DownloadAppJobFeed = dynamic(
  () => import('@jobseeker/lotus/lib/components/DownloadAppJobfeed'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const DownloadAppJobdetail = dynamic(
  () =>
    import('@jobseeker/lotus/lib/components/DownloadAppJobdetailAndCatogary'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const JobFeed = ({
  jobFeedType,
  catOrTagId,
  // catOrTagTitle,
  showProfileCard,
  showFeatured,
  showLearning,
  calledType,
  variant = VersionVariants.VERSION_TWO,
  feedOnly = false,
  showLearningCenterCTA = true,
  preFilters,
  ...props
}: JobFeedLayoutProps) => {
  const dispatch =
    /* eslint-disable @typescript-eslint/no-explicit-any */ useDispatch<any>();

  const router = useRouter();
  const [loginOpen, setLoginOpen] = useState(false);
  const [catOrTagTitle, setCatOrTagTitle] = useState(props.catOrTagTitle);
  const [twoFaActionOpen, settwoFaActionOpen] = useState(false);
  const [pageNo, setPageNo] = useState(preFilters.page + 1);
  const initialFilter = {page: 0, concat: false};

  const interceptor: any = useInterceptor();
  const preFilterApplied = useMemo(() => {
    let value = false;
    if (!preFilters) return preFilters;
    for (const key in preFilters) {
      if (!!preFilters[key] && !['page', 'query'].includes(key)) {
        value = true;
        break;
      }
    }
    return value;
  }, [preFilters]);

  const {
    jobfeed: {singleWidget, mulWidget},
    premiumProducts: {earlyApplicant, resumeBuilder},
    proMembership: {userProfileWidget},
  } = interceptor;

  const isAboveMobile = useIsLargeDevice('sm');
  const isAboveMD = useIsLargeDevice('md');
  let sessionFilter: {minexp?: number; maxexp?: number} = {};
  try {
    if (jobFeedType !== SEARCH) {
      sessionFilter = JSON.parse(sessionStorage.getItem('filter') || '{}');
    }
  } catch (error) {}
  const [filters, setFilters] = useState<any>(
    preFilters
      ? {
          ...sessionFilter,
          ...preFilters,
          minexp: preFilters.minexp || sessionFilter.minexp,
          maxexp: preFilters.maxexp || sessionFilter.maxexp,
        }
      : initialFilter
  );
  const [pageMaps, setPageMaps] = useState<number[]>([]);
  // const [filters, setFilters] = useState<any>(initialFilter);

  const {isLoggedIn, userId} = useSelector(
    (state: AppState) => state.auth,
    shallowEqual
  );

  const {
    profile: {subscriptions, proMembership, settings, tags, id, showSpotLight},
    completeness: {profileStatus},
    interviewSlots,
  } = useSelector((state: AppState) => state.user);

  const isSearchEnabled = useSelector(
    (state: AppState) => state.app.layout.searchOverlay,
    shallowEqual
  );

  const isBotUser = useSelector(
    (state: AppState) => state.app.context.isCrawlerBot,
    shallowEqual
  );

  const {jobfeed, isLoading, loadMoreEnabled, isFilterLoaded, totalJobs} =
    useSelector((state: AppState) => state.job);

  const enableProfileWidget = useMemo(() => {
    if (!isAboveMobile && profileStatus?.resumeAttached) {
      return false;
    }
    return true;
  }, [isAboveMobile, profileStatus?.resumeAttached]);

  const page = useSelector((state: AppState) => state.app.page, shallowEqual);

  const minexp = 0,
    maxexp = 0;

  const getJobFeed = useCallback(() => {
    getJobFeedCallByType(
      jobFeedType,
      {
        maxexp,
        userId,
        type: calledType,
        // pageNo,
        catOrTagId,
        param: filters,
      },
      dispatch
    );
    dispatch({
      type: 'job/jobFeed/set_jobfeed_loading',
      payload: {isLoading: true, loadingSource: 'jobfeedLoading'},
    });
  }, [maxexp, minexp, filters, userId, catOrTagId]);

  useEffect(() => {
    if (
      [CATEGORY, TAG, SEARCH, LOCATION_BASED, RECRUITER_JOBFEED].includes(
        jobFeedType
      ) &&
      filters.page >= 0
    ) {
      getJobFeed();
    }
    if (jobFeedType === JOBFEED && filters.page >= 0) {
      getJobFeed();
    }
  }, [filters, catOrTagId]);

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ preFilters:', preFilterApplied);
    dispatch(fetchIndustryData());

    if (
      (preFilterApplied && ![LOCATION_BASED].includes(jobFeedType)) ||
      jobFeedType === SEARCH
    ) {
      dispatch({
        type: 'job/jobFeed/set_jobfeed_filter_loaded',
        payload: true,
      });
    }
  }, []);

  const debounceOptions = {leading: true};

  const setPageQuery = debounce(
    (page: number) => {
      const initialPage = preFilters?.page || initialFilter.page;
      const pageNo = page + initialPage;
      if (pageNo <= 1) {
        setPageNo(0);
        setQueryParams({page: null});
      } else {
        setPageNo(pageNo);
        setQueryParams({page: pageNo});
      }
    },
    250,
    debounceOptions
  );

  useEffect(() => {
    if (
      getCookie('2faEmailAction', {
        domain:
          process.env.NEXT_PUBLIC_TRAIL === 'true'
            ? 'localhost'
            : process.env.NEXT_PUBLIC_DOMAIN,
      }) == '1'
    ) {
      settwoFaActionOpen(true);
    }
  }, []);

  const onFilter = (
    filterData: /* eslint-disable @typescript-eslint/no-explicit-any */ any,
    clear = false
  ) => {
    const {experience, locationLabels, ...filterParams} = filterData;
    const filterExp: object = EXPERIENCE_MAPPING[parseInt(experience) - 1] ?? {
      minexp: 0,
      maxexp: 0,
    };

    const locationLabel = locationLabels?.replace(/ /g, '-') || '';

    const applyingFilter = {
      ...initialFilter,
      userId,
      catOrTagId,
      ...filterParams,
      //loc: filterParams?.loc?.join(','),
      sort: filterParams.sort?.join(','),
      ...filterExp,
    };

    if (!applyingFilter?.loc && jobFeedType === LOCATION_BASED) {
      applyingFilter['loc'] = preFilters.loc;
    }

    if (!isEqual(applyingFilter, filters)) {
      setFilters(applyingFilter);

      if (jobFeedType === SEARCH) {
        const expFilters = clear ? {minexp: 0, maxexp: 0} : filterExp;
        const {query, ...newFilterParam} = filterParams;
        if (clear) {
          newFilterParam.posting = '';
        }
        const queryData = query ? query : catOrTagId;
        router.replace(
          {
            pathname: filterParams?.query
              ? `${ROUTE_SEARCH}/${kebabCase(
                  queryData?.toLowerCase().replace('&', '')
                )}-jobs`
              : router.pathname,
            query: {
              ...router.query,
              ...expFilters,
              ...newFilterParam,
              loc: locationLabel,
            },
          },
          undefined,
          {shallow: true}
        );

        setQueryParams({
          // ...router.query,
          ...expFilters,
          ...filterParams,
          loc: locationLabel,
          sort: filterData?.sort || null,
        });
      } else {
        setQueryParams({
          // ...router.query,
          ...filterExp,
          ...filterParams,
          loc: locationLabel,
          sort: filterData?.sort || null,
        });
      }

      dispatch({
        type: 'job/jobFeed/set_jobfeed_filter_loaded',
        payload: !clear,
      });
      handleTracking('jsclickJobFilter', {
        pagename: jobFeedType,
        ...filterData,
      });
      TrackEventUBA('filterJobListingClick', 'click', {
        pageName: jobFeedType,
        page: jobFeedType,
        categoryID: catOrTagId,
        tagID: catOrTagId,
        filterExp: JSON.stringify(filterExp),
        filterLocation: Array.isArray(filterData.loc)
          ? filterData.loc.join(',')
          : filterData.loc,
        filterIndustry: Array.isArray(filterData?.industry)
          ? filterData?.industry?.join(',')
          : filterData?.industry,
        sessionUsername: sessionStorage.getItem('userId') || '',
      });
    }

    if (jobFeedType === SEARCH) {
      setCatOrTagTitle(
        `Search for - ${filterData.query ?? (filters.query || '')}`
      );
    }
  };

  const routeToJobDetail = (jobSlug: string, jobId: string | number) => {
    openUrl(`${ROUTE_JOB_DETAIL}/${jobSlug}-${jobId}`);
  };

  useEffect(() => {
    let currentFilters: {[key: string]: string | number} = {};
    let sessionFilter = {};
    try {
      sessionFilter = JSON.parse(sessionStorage.getItem('filter') || '{}');
      if (jobFeedType === SEARCH) {
        sessionFilter = {};
      }
      currentFilters = {...sessionFilter, ...router.query};
      // if experience filter comes from the url, it needs to be set in the session storage
      sessionStorage.setItem(
        'filter',
        JSON.stringify({
          minexp: currentFilters.minexp,
          maxexp: currentFilters.maxexp,
        })
      );
    } catch (error) {}
    const {loc, sort, minexp, maxexp, posting, page, industry} =
      currentFilters as ParsedUrlQuery;
    const location = getLocationIds(loc as string);
    const industryIds = (industry as string)?.split(',');
    const locationIds = location
      ? location?.split(',')?.map(v => parseInt(v))
      : null;
    let experience: any = EXPERIENCE_MAPPING.map(
      (x: any) => `${x.minexp}-${x.maxexp}`
    ).indexOf(`${minexp}-${maxexp}`);
    experience = experience > -1 ? [experience + 1] : null;
    const appliedFilters: any = {
      loc: locationIds,
      sort: sort ? [sort] : null,
      page: parseInt(page as string) || 0,
      experience,
      industry: industryIds,
      // query: preFilters.query,
      // posting: [parseInt(posting as string) || 0],
    };
    if (jobFeedType === SEARCH) {
      appliedFilters.query = preFilters.query;
    }
    if (posting) {
      appliedFilters.posting = [parseInt(posting as string)];
    }
    dispatch({
      type: 'job/jobFeed/set_filters',
      payload: {
        filters: appliedFilters,
        options: {showSort: jobFeedType === JOBFEED},
      },
    });

    const filterMapper = Object.assign({}, appliedFilters);
    delete filterMapper?.page;
    delete filterMapper?.sort;
    if (!Object.values(filterMapper).every(x => !x)) {
      dispatch({
        type: 'job/jobFeed/set_jobfeed_filter_loaded',
        payload: true,
      });
    }

    // if (jobFeedType === JOBFEED) {
    //   setFilters({...appliedFilters});
    // }
  }, []);

  /**
   * Premium products based logic append
   */

  useEffect(() => {
    if (userId && jobFeedType === JOBFEED) {
      (async () => {
        const invites = await calendarService.invites('invites');
        dispatch({
          type: 'User/set_user_interviewslots',
          payload: invites,
        });
      })();
    }
  }, [userId, jobFeedType]);

  const onLoadMore = (event: Event, target: HTMLElement) => {
    pageMaps.push(target.scrollTop || document.documentElement.scrollTop);
    setPageMaps(pageMaps);
    setFilters({
      ...filters,
      page: (filters.page || 0) + 1,
      concat: true,
    });
  };

  const premiumPurchase = [];

  if (showSpotLight) {
    premiumPurchase.push({
      title: 'Spotlight',
    });
  }

  if (proMembership?.isValid) {
    premiumPurchase.push({
      title: 'Pro Membership',
      actionLabel: `Valid till ${moment()
        .add(proMembership?.daysLeft, 'day')
        .format('DD MMM YYYY')}`,
      actionUrl: getProLink(
        'jf',
        '123',
        userProfileWidget?.refCode,
        userProfileWidget?.refFeature
      ),
    });
  }

  if (subscriptions?.earlyApplicant?.isValid && earlyApplicant?.enabled) {
    premiumPurchase.push({
      title: 'Early Applicant',
      actionLabel: `Valid till ${moment(
        subscriptions?.earlyApplicant?.validUpTo,
        'DD-MM-YYYY'
      ).format('DD MMM YYYY')}`,
      actionUrl: getOldLink(earlyApplicant?.redirectUrl),
    });
  }

  if (subscriptions?.resumeBuilder?.isValid && resumeBuilder?.enabled) {
    premiumPurchase.push({
      title: 'Resume Builder',
      actionLabel: `Valid till ${moment(
        subscriptions?.resumeBuilder?.validUpTo,
        'DD-MM-YYYY'
      ).format('DD MMM YYYY')}`,
      actionUrl: getOldLink(resumeBuilder?.redirectUrl),
    });
  }
  const filteredMulWidget = mulWidget?.content?.filter((info: any) => {
    if (info?.tag === 'earlyapplicant' && earlyApplicant?.enabled) {
      if (subscriptions?.earlyApplicant?.isValid) {
        return false;
      }
      return true;
    }

    return true;
  });

  if (
    typeof interviewSlots?.count === 'number' &&
    interviewSlots.count > 0 &&
    interviewSlots?.slots?.length
  ) {
    if (singleWidget?.content[0]?.actionUrl) {
      singleWidget.content[0].actionUrl =
        interviewSlots.count == 1
          ? getRefEmbedded(
              getOldLink(
                `/cal/pick-date/${interviewSlots.slots[0]?.calId || ''}/${
                  interviewSlots.slots[0].id || ''
                }`
              ),
              'WEB_SIDEBAR',
              'normal'
            )
          : getRefEmbedded(
              getOldLink(singleWidget?.content[0]?.actionUrl),
              'WEB_SIDEBAR',
              'normal'
            );
    }
  }

  const onScrollNew = () => {
    if (pageMaps.length) {
      let pageNo = 0;
      for (let i = 0; i <= pageMaps.length; i++) {
        if (pageMaps[i] < document.documentElement.scrollTop) {
          pageNo = pageNo + 1;
        } else {
          break;
        }
      }
      setPageQuery(pageNo + 1);
    }
  };

  const onLoginSuccess = () => {
    if (isCSR()) {
      window.location.reload();
    }
  };

  const onJobSave = () => {
    if (!isLoggedIn) {
      setLoginOpen(true);
    }
  };

  const handleClearFilter = () => {
    dispatch({
      type: 'job/jobFeed/set_filter_chips',
      payload: {chips: []},
    });
    onFilter(initialFilter, true);
    sessionStorage.removeItem('filter');
  };

  const disable2FAEmailActionPopup = () => {
    deleteCookie('2faEmailAction', {
      domain:
        process.env.NEXT_PUBLIC_TRAIL === 'true'
          ? 'localhost'
          : process.env.NEXT_PUBLIC_DOMAIN,
    });
  };

  const handle2FAClickAction = (path: string) => {
    openUrl(getOldLink(path), '_self');
  };
  const h1Title =
    catOrTagTitle.includes('Jobs') || catOrTagTitle.includes('jobs')
      ? catOrTagTitle
      : `${catOrTagTitle} Jobs`;

  const sendEvent = (
    eventCatogary: string,
    eventName: string,
    action: string
  ) => {
    handleTracking(`${eventName}_${action}`, {
      event_category: `${eventCatogary}`,
      Origin: page,
      UserId: sessionStorage.getItem('userId') || 'NA',
      Device: sessionStorage.getItem('device') || 'desktop',
      Device_Type: sessionStorage.getItem('deviceType') || 'web',
      Status: sessionStorage.getItem('status') || 'LoggedOut',
      Php_SessionID_Mod: sessionStorage.getItem('phpSessId') || '0',
    });
  };

  useEffect(() => {
    if (page === JOBFEED && isAboveMD) {
      sendEvent('App_Promotion', 'DownloadAppQR2Desktop', 'View');
    }
    if ((page === TAG || page === CATEGORY) && isAboveMD) {
      sendEvent('App_Promotion', 'DownloadAppQR1Desktop', 'View');
    }
  }, [page, isAboveMD]);
  const [SeoMetas, setSEOMetas] = useState({title: '', description: ''});

  useEffect(() => {
    if (page !== JOBFEED) {
      const {title, description} = getListSEOContent(
        props.catOrTagTitle,
        pageNo,
        totalJobs
      );
      setSEOMetas({
        title: title,
        description: description,
      });
    }
  }, [totalJobs]);

  return (
    <>
      {isCSR() && (
        <SeoIntrup
          pageNo={pageNo}
          nextPage={!!loadMoreEnabled}
          type={'jobfeed'}
          title={SeoMetas.title}
          description={SeoMetas.description}
        />
      )}
      <Grid container rowSpacing={2} columnSpacing={2}>
        {showProfileCard && enableProfileWidget && (
          <Grid
            item
            xxs={12}
            md={2.3}
            xl={2}
            display={{xss: 'block', md: 'none', lg: 'block'}}
          >
            <ProfileCardHarness showSmallBadges={false} variant={variant} />

            {isAboveMobile &&
              singleWidget?.enabled &&
              typeof interviewSlots?.count === 'number' &&
              interviewSlots?.count > 0 && (
                <CTA
                  cardSxProps={{
                    mt: 2,
                  }}
                  cardInfo={singleWidget?.content}
                  variant={VersionVariants.VERSION_FOUR}
                  dynamicValue={interviewSlots?.count.toString()}
                  valueToReplace="${slots}"
                />
              )}

            {isAboveMobile && premiumPurchase.length > 0 && (
              <CTA
                cardSxProps={{
                  mt: 2,
                }}
                cardInfo={premiumPurchase}
                variant={VersionVariants.VERSION_FIVE}
              />
            )}

            {isAboveMobile && mulWidget?.enabled && (
              <CTA
                cardSxProps={{
                  mt: 2,
                }}
                cardInfo={filteredMulWidget}
                variant={VersionVariants.VERSION_FOUR}
              />
            )}
            {isAboveMD && page === JOBFEED && (
              <DownloadAppJobFeed sendEvent={sendEvent} />
            )}
          </Grid>
        )}

        <Grid
          item
          xxs={12}
          md={feedOnly ? 12 : showProfileCard ? 6.7 : 8.5}
          xl={feedOnly ? 12 : showProfileCard ? 7 : 9}
        >
          <InjectSearchOverlay isSearchEnabled={isSearchEnabled} />
          {(() => {
            if (jobFeedType === JOBFEED && id && !tags?.length) {
              return (
                <JobFeedEmpty
                  noTags={!tags?.length}
                  title={jobFeedType === JOBFEED ? MYJOBFEED : h1Title}
                />
              );
            } else if (
              (jobFeedType !== SEARCH &&
                !isFilterLoaded &&
                !jobfeed?.length &&
                isLoading === false) ||
              (isBotUser && !jobfeed?.length)
            ) {
              return (
                <JobFeedEmpty
                  noTags={false}
                  title={h1Title}
                  page={jobFeedType}
                />
              );
            } else {
              return (
                <>
                  <JobfeedHarness
                    onLoadMore={onLoadMore}
                    routeToJobDetail={routeToJobDetail}
                    title={jobFeedType === JOBFEED ? MYJOBFEED : h1Title}
                    feedOnly={feedOnly}
                    onFilter={onFilter}
                    onClearFilter={handleClearFilter}
                    variant={variant}
                    // onInfiniteScroll={(e: MouseEvent, page: number) =>
                    //   setPageQuery(page)
                    // }
                    onJobSave={onJobSave}
                    onInfiniteScroll={onScrollNew}
                    infiniteScroll={
                      jobFeedType === RECRUITER_JOBFEED ? false : true
                    }
                  />
                </>
              );
            }
          })()}
        </Grid>
        {!feedOnly && (
          <Grid
            item
            // md={showProfileCard ? 3.3 : 4}
            md={showProfileCard ? 3 : 3.5}
            xl={showProfileCard ? 3 : 3}
            sx={{
              display: {xxs: !!loadMoreEnabled ? 'none' : 'block', md: 'block'},
            }}
          >
            {jobFeedType === JOBFEED && !settings?.['hideStories'] && (
              <StoryInjection />
            )}
            <AdsPanelHarness
              variant={variant}
              showSmallBadges={false}
              showFeatured={showFeatured}
              showLearning={showLearning}
              showLearningCenterCTA={showLearningCenterCTA}
            />
            {isAboveMD && (page == TAG || page == CATEGORY) && (
              <DownloadAppJobdetail sendEvent={sendEvent} />
            )}
          </Grid>
        )}
        <Dialog
          title="Login to your account"
          subtitle="Welcome back! 👋"
          invertedTitle
          variant={VersionVariants.VERSION_TWO}
          open={loginOpen}
          onClose={() => setLoginOpen(false)}
        >
          <LoginForm
            onSuccess={onLoginSuccess}
            variant={VersionVariants.VERSION_TWO}
          />
        </Dialog>
        <Dialog
          title="2-Step Verification"
          subtitle="Action Required!"
          invertedTitle={true}
          variant={VersionVariants.VERSION_TWO}
          open={twoFaActionOpen}
          onClose={() => {
            settwoFaActionOpen(false);
            disable2FAEmailActionPopup();
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap={1}
            px={1}
            pt={1}
          >
            <Image
              src={CautionIcon}
              alt={'caution'}
              data-name="2fa"
              width={32}
              height={32}
              objectFit="cover"
              quality={50}
            />
            <Typography
              color="secondary.main"
              variant="subtitle2"
              lineHeight={'21px'}
            >
              {
                "We noticed that you do not have access to the phone number you've enabled for 2-Step Verification."
              }
            </Typography>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            gap={1}
            px={1}
            pt={1}
            pb={2}
          >
            <Typography color="#7e7e7e" lineHeight={'18px'} variant="body3">
              {
                'Before continuing, we request you to change your number to ensure a smoother experience while logging in to your iimjobs account. Alternatively, you may also disable the 2-Step Verification.'
              }
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            onClick={() => disable2FAEmailActionPopup()}
            gap={1.5}
            px={1}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              border={'1px solid #7e7e7e20'}
              borderRadius={2}
              px={1.5}
              py={1}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handle2FAClickAction('/mfasettings?cn=1')}
            >
              <Link
                href={getOldLink('/mfasettings?cn=1')}
                target="_blank"
                color="secondary.main"
                fontSize={'12px'}
                lineHeight={'16px'}
                fontWeight={500}
                sx={{
                  textDecoration: 'none',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {'Change number'}
              </Link>
              <Image
                src={ChevronRight}
                alt={'Arrow'}
                width={12}
                height={12}
                objectFit="cover"
              />
            </Box>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              border={'1px solid #7e7e7e20'}
              borderRadius={2}
              px={1.5}
              py={1}
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handle2FAClickAction('/mfasettings?da=1')}
            >
              <Link
                href={getOldLink('/mfasettings?da=1')}
                target="_blank"
                color="secondary.main"
                fontSize={'12px'}
                lineHeight={'16px'}
                fontWeight={500}
                sx={{
                  textDecoration: 'none',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {'Disable 2-Step Verification'}
              </Link>
              <Image
                src={ChevronRight}
                alt={'Arrow'}
                width={12}
                height={12}
                objectFit="cover"
              />
            </Box>
            <Button
              onClick={() => settwoFaActionOpen(false)}
              sx={{
                color: 'primary.main',
                padding: '0px 0px 8px 12px',
                ':hover': {
                  textDecoration: 'underline',
                  background: '#fff',
                },
              }}
            >
              {"I'll do this later"}
            </Button>
          </Box>
        </Dialog>
      </Grid>
    </>
  );
};

export default JobFeed;
