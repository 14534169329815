import useInterceptor from '@jobseeker/common-core/lib/hooks/useInterceptor';
import {ReactNode} from 'react';
import {useSearchParams} from 'next/navigation';
import {Props as FooterProps} from '@jobseeker/lotus/lib/components/RegistrationFooter/types';
import {Box, Typography} from '@mui/material';
import Image from 'next/image';
import {RegistrationFooter, ProgressBar} from '@jobseeker/lotus/lib/components';
import {shallowEqual, useSelector} from 'react-redux';
import type {AppState} from '@jobseeker/common-core/lib/types';
import {ChevronLeft} from '@jobseeker/lotus/lib/assets/icons';
import {
  REGISER_PERSONAL_DETAILS,
  REGISTER_WORK_EXPERIENCE,
  REGISTER_EDUCATIONAL_DETAILS,
  REGISTER_ADDITIONAL_DETAILS,
  REGISTER_COVERLETTERANDSKILLS,
  REGISTER_AUDIO_VIDEO_PROFILE,
} from '@constants';
import {
  openUrl,
  appendQueryParams,
} from '@jobseeker/common-core/lib/utils/common';
import {profileService} from '@jobseeker/common-core/lib/services';
interface LayoutProps extends FooterProps {
  children: ReactNode;
  variant?: string;
}

const RegistrationLayout = (props: LayoutProps) => {
  const {
    children,
    onSubmit = () => {},
    submitDisabled,
    loading,
    disableFooter,
  } = props || {};
  const interceptor: /* eslint-disable @typescript-eslint/no-explicit-any */ any =
    useInterceptor();
  const page = useSelector((state: AppState) => state.app.page, shallowEqual);
  const {profileStatus} = useSelector(
    (state: AppState) => state.user.completeness
  );

  const {profileLoading} = useSelector((state: AppState) => state.user);

  const searchParams = Object.fromEntries(useSearchParams()?.entries() || []);

  const {backCTA, skipCTA, submitCTA} = interceptor[page];

  const onWorkSkip = async () => {
    await profileService.skipProfesssion();
  };

  const ProgessSteps = [
    {
      active: page === REGISER_PERSONAL_DETAILS,
      link: appendQueryParams(
        interceptor[REGISER_PERSONAL_DETAILS].link,
        searchParams
      ),
      label: interceptor[REGISER_PERSONAL_DETAILS].label,
      backUrl: '',
      forwardUrl: appendQueryParams(
        interceptor[REGISTER_EDUCATIONAL_DETAILS].link,
        searchParams
      ),
    },
    {
      active: page === REGISTER_EDUCATIONAL_DETAILS,
      link: profileStatus.personalDetails
        ? appendQueryParams(
            interceptor[REGISTER_EDUCATIONAL_DETAILS].link,
            searchParams
          )
        : '',
      label: interceptor[REGISTER_EDUCATIONAL_DETAILS].label,
      backUrl: appendQueryParams(
        interceptor[REGISER_PERSONAL_DETAILS].link,
        searchParams
      ),
      forwardUrl: appendQueryParams(
        interceptor[REGISTER_WORK_EXPERIENCE].link,
        searchParams
      ),
    },
    {
      active: page === REGISTER_WORK_EXPERIENCE,
      link: profileStatus.educationDetails
        ? appendQueryParams(
            interceptor[REGISTER_WORK_EXPERIENCE].link,
            searchParams
          )
        : '',
      label: interceptor[REGISTER_WORK_EXPERIENCE].label,
      backUrl: appendQueryParams(
        interceptor[REGISTER_EDUCATIONAL_DETAILS].link,
        searchParams
      ),
      forwardUrl: appendQueryParams(
        interceptor[REGISTER_ADDITIONAL_DETAILS].link,
        searchParams
      ),
    },
    {
      active: page === REGISTER_ADDITIONAL_DETAILS,
      link: profileStatus.educationDetails
        ? appendQueryParams(
            interceptor[REGISTER_ADDITIONAL_DETAILS].link,
            searchParams
          )
        : '',
      label: interceptor[REGISTER_ADDITIONAL_DETAILS].label,
      backUrl: appendQueryParams(
        interceptor[REGISTER_WORK_EXPERIENCE].link,
        searchParams
      ),
      forwardUrl:
        searchParams?.has == '1'
          ? profileStatus.followKeywords
            ? '/jobfeed'
            : '/personalize-jobfeed?fl=rg'
          : appendQueryParams(
              interceptor[REGISTER_AUDIO_VIDEO_PROFILE].link,
              searchParams
            ),
    },
    {
      active: page === REGISTER_AUDIO_VIDEO_PROFILE,
      link: profileStatus.educationDetails
        ? appendQueryParams(
            interceptor[REGISTER_AUDIO_VIDEO_PROFILE].link,
            searchParams
          )
        : '',
      label: interceptor[REGISTER_AUDIO_VIDEO_PROFILE].label,
      backUrl: appendQueryParams(
        interceptor[REGISTER_ADDITIONAL_DETAILS].link,
        searchParams
      ),
      forwardUrl: appendQueryParams(
        interceptor[REGISTER_COVERLETTERANDSKILLS].link,
        searchParams
      ),
    },
    {
      active: page === REGISTER_COVERLETTERANDSKILLS,
      link: profileStatus.educationDetails
        ? appendQueryParams(
            interceptor[REGISTER_COVERLETTERANDSKILLS].link,
            searchParams
          )
        : '',
      label: interceptor[REGISTER_COVERLETTERANDSKILLS].label,
      backUrl: appendQueryParams(
        interceptor[REGISTER_AUDIO_VIDEO_PROFILE].link,
        searchParams
      ),
      forwardUrl: profileStatus.followKeywords
        ? '/jobfeed'
        : '/personalize-jobfeed?fl=rg',
    },
  ];

  // hide last 2 steps for url params has=1
  if (searchParams?.has == '1') {
    ProgessSteps.splice(ProgessSteps.length - 2, 2);
  }

  return (
    <Box position={'relative'}>
      <Box
        display={'flex'}
        justifyContent={{xxs: 'flex-start', md: 'center'}}
        alignItems={'flex-start'}
        flexDirection={{xxs: 'column', md: 'row'}}
        sx={{
          mb: {xxs: 6, md: 10},
        }}
      >
        <Box
          width={{xxs: 'calc(100% - 28px)', md: 'auto'}}
          ml={-0.5}
          mt={{xxs: -1.25, md: 5.625}}
          pt={2}
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          flexDirection={{xxs: 'column', md: 'row'}}
          position={'fixed'}
          left={{xxs: 'auto', md: '5%', lg: '8%', xl: '10%', xxl: '18%'}}
          zIndex={100}
          sx={{
            background: {xxs: '#fff', md: 'transparent'},
          }}
        >
          <Box
            ml={0.5}
            display={{xxs: backCTA ? 'flex' : 'none', md: 'none'}}
            justifyContent={'center'}
            alignItems={'center'}
            gap={0.625}
            onClick={() => {
              const activeSteps = ProgessSteps.filter(step => step.active);
              if (activeSteps?.length && activeSteps[0]?.backUrl) {
                openUrl(activeSteps[0].backUrl, '_self');
              }
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Box
              width={1.5}
              height={1.5}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                transform: 'rotate(180deg)',
              }}
            >
              <Image src={ChevronLeft} alt="" width={12} height={12} />
            </Box>
            <Typography
              variant="subtitle1"
              color="primary.main"
              lineHeight={'24px'}
            >
              {backCTA}
            </Typography>
          </Box>
          <Box width={'100%'} mt={2}>
            <ProgressBar steps={ProgessSteps} disableFooter={disableFooter} />
          </Box>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          boxShadow={{xxs: 'none', md: '0px 9px 9px 0px #00000014'}}
          border={{xxs: '0px', md: '1px solid #E0E0E0'}}
          borderRadius={4}
          width={{xxs: '100%', md: 840}}
          ml={{xxs: 0, md: 32}}
          mt={{xxs: 0, md: 5.5}}
          sx={{
            transition: 'all ease 0.2s',
            background: '#fff',
          }}
        >
          <Box
            width={{
              xxs: '100%',
              md: page === REGISTER_AUDIO_VIDEO_PROFILE ? 600 : 540,
              lg: page === REGISTER_AUDIO_VIDEO_PROFILE ? 680 : 540,
            }}
            ml={{xxs: 0, md: 7.5}}
            mt={{xxs: backCTA ? 9 : 7, md: 5.5}}
            mb={{xxs: 0, md: 6.25}}
            mr={{xxs: 0, md: 1}}
          >
            {children}
          </Box>
        </Box>
      </Box>
      <Box
        position={'fixed'}
        bottom={0}
        left={0}
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        className="registrationFooter"
      >
        <RegistrationFooter
          backCTA={backCTA}
          skipCTA={
            page === REGISTER_WORK_EXPERIENCE
              ? profileLoading === false
                ? profileStatus.professionalDetails
                  ? ''
                  : skipCTA
                : ''
              : skipCTA
          }
          submitCTA={
            page === REGISTER_ADDITIONAL_DETAILS && searchParams?.has == '1'
              ? 'Finish'
              : submitCTA
          }
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
          loading={loading}
          steps={ProgessSteps}
          disableFooter={disableFooter}
          onSkip={page === REGISTER_WORK_EXPERIENCE ? onWorkSkip : undefined}
        />
      </Box>
    </Box>
  );
};

export default RegistrationLayout;
